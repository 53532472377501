import { GetOperatorListApi } from '@/api'

export default {
  data() {
    return {
      // 运营商列表
      operatorList: []
    }
  },

  methods: {
    async getOperatorList(p) {
      const { ret, data, msg } = await GetOperatorListApi({
        ...p
      })
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.operatorList = data.data
    }
  },

  computed: {
    // 返回所有的运营商, 包含全部运营商字样
    allOperatorList() {
      return [
        {
          id_operator: '',
          name_operator: '全部运营商'
        }
      ].concat(this.operatorList)
    }
  }
}

// {
//   cnt_data: 1,
//   data: {
//     addr: ' ',
//     admin_info: {
//       account_type: 3,
//       device_array: null,
//       id: 103,
//       id_operator: 117,
//       real_name: null,
//       role: 1,
//       state: 1,
//       tel: null,
//       time_create: '2022-06-07 09:17:01',
//       time_login: '2022-06-07 09:17:01',
//       time_update: '2022-06-07 09:17:01',
//       user_name: 'fmg2'
//     },
//     id_operator: 117,
//     name_chief: 'f明光',
//     name_operator: '前端运营商',
//     tel: '13720118418',
//     time_create: '2022-06-07 09:17:01',
//     time_update: '2022-06-07 09:17:01'
//   }
// }
