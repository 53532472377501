<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :title="isEdit ? '修改项目' : '添加项目'"
    width="800px"
    @closed="clearForm"
    :closeOnClickModal="false"
    center
  >
    <div class="form_box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="formRef"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目名称" prop="name_project">
              <el-input
                v-model.trim="ruleForm.name_project"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="level === 100">
            <template v-if="!isEdit">
              <el-form-item label="运营商" prop="id_operator">
                <el-select
                  v-model="ruleForm.id_operator"
                  placeholder="请选择运营商"
                  class="w100"
                >
                  <el-option
                    v-for="item in operatorList"
                    :key="item.id_operator"
                    :label="item.name_operator"
                    :value="item.id_operator"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item label="运营商">
                <el-input :value="ruleForm.name_operator" disabled></el-input>
              </el-form-item>
            </template>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { check_project_name, check_operator } from '@/utils/validator.js'
import { AddProjectApi, UpdateProjectApi } from '@/api'
import operator from '@/mixins/operator.js'

export default {
  mixins: [operator],
  data() {
    return {
      // 权限级别
      level: '',
      // 是否显示弹窗
      dialogVisible: false,
      // 编辑还是修改
      isEdit: false,
      // 表单
      ruleForm: {
        id_project: '', // 修改用
        name_project: '',
        id_operator: '',
        name_operator: ''
      },
      // 规则
      rules: {
        name_project: check_project_name,
        id_operator: check_operator
      }
    }
  },
  methods: {
    // 添加项目
    add() {
      this.isEdit = false
      this.dialogVisible = true
      this.level = this.$store.getters.level
      if (this.level === 100) {
        this.getOperatorList()
      }
    },

    // 编辑项目
    edit(row) {
      this.isEdit = true
      this.level = this.$store.getters.level
      this.dialogVisible = true
      this.$nextTick(() => {
        this.ruleForm.id_project = row.id
        this.ruleForm.name_operator = row.name_operator
        this.ruleForm.name_project = row.name_project
      })
    },

    // 点击确定
    confirm() {
      this.$refs.formRef.validate(_ => {
        if (!_) return
        if (this.isEdit) {
          this.editProject()
        } else {
          this.addProject()
        }
      })
    },

    // 添加项目
    async addProject() {
      const params = {
        id_operator: this.ruleForm.id_operator,
        name_project: this.ruleForm.name_project
      }
      const { ret, data, msg } = await AddProjectApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('添加成功')
      this.dialogVisible = false
      this.$emit('refresh')
    },

    // 编辑项目
    async editProject() {
      const params = {
        id_project: this.ruleForm.id_project,
        name_project: this.ruleForm.name_project
      }
      const { ret, data, msg } = await UpdateProjectApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('修改成功')
      this.$emit('refresh')
      this.dialogVisible = false
    },

    // 清除表单校验
    clearForm() {
      try {
        this.$refs.formRef.resetFields()
      } catch (exception) {}
    }
  }
}
</script>

<style lang="scss" scoped>
.form_box {
  padding: 50px 20px 30px;
}
</style>
