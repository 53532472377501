var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project"},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"side_content"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"color"}),_c('span',[_vm._v("项目管理")])]),_c('div',{staticClass:"tree_box project_tree_box"},[_c('el-tree',{ref:"treeRef",attrs:{"props":{
              label: 'name',
              children: 'zone',
              isLeaf: 'leaf'
            },"highlight-current":"","load":_vm.loadNode,"lazy":""},on:{"node-click":_vm.nodeClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var node = ref.node;
            var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('div',{staticClass:"tree_content"},[_c('span',[_vm._v(_vm._s(data.name.length > 16 ? data.name.substring(0, 14) + '...' : data.name))])])])}}])})],1)])]),_c('el-col',{attrs:{"span":18}},[_c('div',{staticClass:"content"},[(_vm.showContent === 1)?_c('ProjectList',{attrs:{"projectList":_vm.projectList},on:{"refresh":_vm.refresh}}):_c('BuildList',{attrs:{"buildList":_vm.buildList,"id_project":_vm.id_project},on:{"refresh":_vm.refreshBuild}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }