<template>
  <div class="project_cpt">
    <div class="operate_btn">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="addProject"
        >新建项目</el-button
      >
    </div>

    <div class="table_box">
      <el-table
        :data="projectList"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#fafafb'
        }"
        size="small"
      >
        <el-table-column label="项目名称" prop="name_project"></el-table-column>
        <el-table-column
          label="运营商"
          prop="name_operator"
          v-if="level === 100"
        ></el-table-column>
        <el-table-column label="楼栋数量">
          <template v-slot="scope">
            <span>{{ scope.row.cnt_building }}</span>
          </template>
        </el-table-column>
        <el-table-column label="房间数量">
          <template v-slot="scope">
            <span>{{ scope.row.cnt_room }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="time_create"></el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <div class="table_control">
              <img
                src="../../../assets/table_edit.png"
                title="编辑"
                @click="editProject(scope.row)"
              />
              <img
                src="../../../assets/table_delete.png"
                title="删除"
                @click="showAlert(scope.row)"
              />
              <img
                src="../../../assets/temp_icon.png"
                title="温度区间"
                @click="showTempDialog(scope.row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <ChangeProject ref="changeProjectRef" @refresh="refresh" />

    <!-- 确认消息弹窗 -->
    <Alert ref="alertRef" @confirm="delProject" />

    <!-- 温度区间 通用弹窗 -->
    <TempRange ref="tempRangeRef" @confirm="setTempRange" />
  </div>
</template>

<script>
import ChangeProject from './dialogs/change_project.vue'
import Alert from '@/components/alert/alert.vue'
import { DelProjectApi, SetProjectTempApi } from '@/api'
import TempRange from '@/components/temp_range/temp_range.vue'

export default {
  components: { ChangeProject, Alert, TempRange },
  props: ['projectList'],
  data() {
    return {
      // 权限级别
      level: '',
      // 删除的项目
      delInfo: null,
      // 设置温度区间的项目
      tempInfo: null
    }
  },
  methods: {
    // 添加项目
    addProject() {
      this.$refs.changeProjectRef.add()
    },

    // 编辑项目
    editProject(row) {
      this.$refs.changeProjectRef.edit(row)
    },

    // 显示删除项目弹窗
    showAlert(info) {
      this.delInfo = info
      this.$refs.alertRef.alert({
        msg: '您确定要删除当前项目吗? 该操作不可恢复!'
      })
    },

    // 删除项目
    async delProject() {
      const params = { id_project: this.delInfo.id }
      const { ret, data, msg } = await DelProjectApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('删除成功')
      this.$emit('refresh')
    },

    // 子组件事件 通知父组件刷新
    refresh() {
      this.$emit('refresh')
    },

    // 显示温度区间弹窗
    showTempDialog(row) {
      this.tempInfo = row
      this.$refs.tempRangeRef.open(0)
    },

    // 设置温度区间
    async setTempRange(args) {
      const params = {
        ...args,
        id_project: this.tempInfo.id
      }
      const { ret, data, msg } = await SetProjectTempApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('设置成功')
    }
  },
  created() {
    this.level = this.$store.getters.level
    console.log(this.level, '权限级别')
  }
}
</script>

<style lang="scss" scoped>
.project_cpt {
  padding: 22px;
}

.operate_btn {
  text-align: right;
  padding-right: 35px;
}

.table_box {
  margin-top: 21px;
}
</style>
