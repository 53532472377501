<template>
  <div class="project">
    <el-row :gutter="10">
      <el-col :span="6">
        <div class="side_content">
          <div class="head">
            <div class="color"></div>
            <span>项目管理</span>
          </div>

          <div class="tree_box project_tree_box">
            <el-tree
              ref="treeRef"
              :props="{
                label: 'name',
                children: 'zone',
                isLeaf: 'leaf'
              }"
              highlight-current
              :load="loadNode"
              lazy
              @node-click="nodeClick"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <div class="tree_content">
                  <span>{{
                    data.name.length > 16
                      ? data.name.substring(0, 14) + '...'
                      : data.name
                  }}</span>
                </div>
              </span>
            </el-tree>
          </div>
        </div>
      </el-col>
      <el-col :span="18">
        <div class="content">
          <ProjectList
            v-if="showContent === 1"
            :projectList="projectList"
            @refresh="refresh"
          />
          <BuildList
            :buildList="buildList"
            :id_project="id_project"
            v-else
            @refresh="refreshBuild"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { GetOperatorListApi, GetProjectApi, GetBuildApi } from '@/api'
import ProjectList from './cpts/project_list.vue'
import BuildList from './cpts/build_list.vue'

export default {
  name: 'project',
  components: { ProjectList, BuildList },
  data() {
    return {
      // 权限级别
      level: '',
      // 选中的运营商id
      id_operator: '',
      // 选中的项目id
      id_project: '',
      // 项目数据
      projectList: [],
      // 楼栋列表
      buildList: [],
      // 显示的内容 1, 显示项目列表 2显示楼栋列表
      showContent: 1,
      // tree 节点 resolve
      treeResolves: null
    }
  },
  methods: {
    // 获取运营商列表
    async getOperator() {
      const { ret, data, msg } = await GetOperatorListApi()
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      const treeData = []
      data.data.map(i => {
        treeData.push({
          name: i.name_operator,
          id: i.id_operator,
          lv: 1
        })
      })
      return treeData
    },

    // 获取项目列表
    async getProject(p) {
      const params = { ...p }
      const { ret, data, msg } = await GetProjectApi(params)
      if (ret !== 0) {
        this.$message.error(msg)
        return []
      }
      this.projectList = data.data
      const treeData = []
      data.data.map(i => {
        treeData.push({
          id: i.id,
          name: i.name_project,
          lv: 2,
          leaf: true
        })
      })
      return treeData
    },

    // 获取楼栋
    async getBuild(id) {
      const params = {
        id_project: id
      }
      const { ret, data, msg } = await GetBuildApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.buildList = data.data
    },

    // 加载节点
    async loadNode(node, resolve) {
      console.log('execute loadNode func')
      const { level, data } = node
      if (level === 0) {
        // 加载第一层级的内容
        if (this.level === 100) {
          const treeData = await this.getOperator()
          resolve(treeData)
        } else {
          // 普通用户进来, 直接加载默认的项目管理
          resolve([
            {
              id: -1,
              lv: 1,
              name: '项目管理'
            }
          ])
        }
      } else {
        this.treeResolves = { node, resolve }
        // 加载第二层级的内容
        const params = {}
        if (data.id > 0) {
          params.id_operator = data.id
          this.id_operator = data.id
        }
        const treeData = await this.getProject(params)
        resolve(treeData)
      }
    },

    // 节点点击事件 渲染对应的组件
    nodeClick(row, { data }, cpt) {
      this.showContent = row.lv
      if (row.lv === 1) {
        if (this.id_operator !== data.id) {
          this.id_operator = data.id
          if (data.id > 0) {
            this.getProject({ id_operator: data.id })
          } else {
            this.getProject()
          }
        }
      } else if (row.lv === 2) {
        this.id_project = row.id
        this.getBuild(row.id)
      }
    },

    // 唯一需要刷新的就是项目
    refresh() {
      if (this.treeResolves) {
        this.loadNode(this.treeResolves.node, this.treeResolves.resolve)
      } else {
        this.getProject()
      }
    },

    // 刷新楼栋
    refreshBuild() {
      this.getBuild(this.id_project)
    }
  },
  created() {
    this.level = this.$store.getters.level
    this.getProject()
  }
}
</script>

<style lang="scss" scoped>
.project {
  padding: 22px;
  box-sizing: border-box;
}

.side_content {
  min-height: calc(100vh - 164px);
  background-color: #fff;

  .head {
    height: 48px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebedf2;

    .color {
      width: 4px;
      height: 18px;
      background: linear-gradient(-75deg, #50e379, #50e398);
      box-shadow: 0px 3px 4px 0px rgba(80, 227, 146, 0.35);
      margin: 0 11px 0 26px;
    }

    span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #12203e;
    }
  }

  .tree_box {
    width: 80%;
    border-radius: 10px;
    margin: 36px auto;
    overflow: hidden;

    .tree_content {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 22px;
        height: 22px;
      }

      span {
        font-size: 14px;
        font-family: Alibaba;
        font-weight: normal;
        color: #5b6680;
        margin-left: 15px;
      }
    }
  }
}

.content {
  min-height: calc(100vh - 164px);
  background-color: #fff;
}
</style>
<style lang="scss">
.project_tree_box {
  .el-tree-node__content {
    height: 46px;
  }

  .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-color: rgbA(80, 227, 153, 0.1);
    border-radius: 6px;
    color: rgb(80, 227, 153);
  }
}
</style>
