// 校验手机号
const checkTel = (rule, value, cb) => {
  const reg = /^1[0-9]{10}$/
  if (!reg.test(value)) {
    return cb(new Error('手机号格式错误'))
  }
  cb()
}

// 校验用户名
const checkUserName = (rule, value, cb) => {
  const reg = /^[a-zA-Z0-9_-]{1,24}$/
  if (!reg.test(value)) {
    return cb(new Error('用户名仅可输入[0-9a-zA-Z_-]字符'))
  }
  cb()
}

// 校验密码 必选参数
const checkPwd = (rule, value, cb) => {
  const reg = /^[0-9a-zA-Z_-]{1,24}$/
  if (!reg.test(value)) {
    return cb(new Error('密码仅可输入[0-9a-zA-Z_-]字符'))
  }
  // 大小写和数字
  if (!/[A-Z]/.test(value) || !/[a-z]/.test(value) || !/[0-9]/.test(value)) {
    return cb(new Error('密码需同时包含大小写字母和数字'))
  }
  cb()
}

// 校验密码 可选参数
const _checkPwd = (rule, value, cb) => {
  const reg = /^[0-9a-zA-Z_-]{1,24}$/
  if (!value) cb()
  if (!reg.test(value)) {
    return cb(new Error('密码仅可输入[0-9a-zA-Z_-]字符'))
  }
  // 大小写和数字
  if (!/[A-Z]/.test(value) || !/[a-z]/.test(value) || !/[0-9]/.test(value)) {
    return cb(new Error('密码需同时包含大小写字母和数字'))
  }
  cb()
}

// 校验批量添加设备名, 比较特殊
const checkSomeName = (rule, value, cb) => {
  const v = value.trim()
  const reg = /.*?[0-9]{3}$/
  if (!reg.test(v)) {
    return cb(new Error('格式为 设备名 + 三个数字'))
  }
  cb()
}

// 校验数字
const checkNum = (rule, value, cb) => {
  const num = parseInt(value.trim())
  if (num > 0 && num < 100) {
    cb()
  } else {
    cb(new Error('限制输入 1 ~ 99'))
  }
}

// 运营商 1 - 16位
const check_name_operator = [
  { required: true, message: '请输入运营商名称', trigger: 'blur' },
  { min: 1, max: 16, message: '运营商长度 1 ~ 16 位', trigger: 'blur' }
]

// 联系人 1 - 4位
const check_name_chief = [
  { required: true, message: '请输入姓名', trigger: 'blur' },
  { min: 1, max: 4, message: '姓名长度 1 ~ 4 位', trigger: 'blur' }
]

// 校验手机号 1开头+10位数字
const check_tel = [
  { required: true, message: '请输入手机号', trigger: 'blur' },
  { validator: checkTel, trigger: 'blur' }
]

// 用户名, 限输入 [a-zA-Z0-9_-]
const check_user_name = [
  { required: true, message: '请输入用户名', trigger: 'blur' },
  { validator: checkUserName, trigger: 'blur' }
]

// 密码 限输入 [a-zA-Z0-9_-] 且包含大小写字母和数字
const check_pwd = [
  { required: true, message: '请输入密码', trigger: 'blur' },
  { validator: checkPwd, trigger: 'blur', required: false }
]

// 同上 非必选
const _check_pwd = [
  { required: false, message: '请输入密码', trigger: 'blur' },
  { validator: _checkPwd, trigger: 'blur', required: false }
]

// 运营商选择器
const check_operator = [
  { required: true, message: '请选择运营商', trigger: 'change' }
]

// 项目选择器
const check_project = [
  { required: true, message: '请选择项目', trigger: 'change' }
]

// 角色选择器
const check_role = [
  { required: true, message: '请选择角色', trigger: 'change' }
]

// 项目名称
const check_project_name = [
  { required: true, message: '请输入项目名', trigger: 'blur' },
  { min: 1, max: 16, message: '项目名长度为 1 ~ 16 位', trigger: 'blur' }
]

// 楼栋名称
const check_build_name = [
  { required: true, message: '请输入楼栋名', trigger: 'blur' },
  { min: 1, max: 20, message: '楼栋名长度为 1 ~ 20 位', trigger: 'blur' }
]

// 设备名称校验
const check_device_name = [
  { required: true, message: '请输入设备名', trigger: 'blur' },
  { min: 1, max: 16, message: '设备名长度为 1 ~ 16 位', trigger: 'blur' }
]

// 批量添加设备名称校验
const check_some_device_name = [
  { required: true, message: '请输入设备名', trigger: 'blur' },
  { min: 1, max: 16, message: '设备名长度为 1 ~ 16 位', trigger: 'blur' },
  { validator: checkSomeName, trigger: 'blur' }
]

// 数量
const check_num = [
  { required: true, message: '请输入数量', trigger: 'blur' },
  { validator: checkNum, trigger: 'blur' }
]

// 选择楼栋
const check_build = [
  { required: true, message: '请选择楼栋', trigger: 'change' }
]

// 选择楼层
const check_floor = [
  { required: true, message: '请选择楼层', trigger: 'change' }
]

// 房间名称校验
const check_name_room = [
  { required: true, message: '请选择/输入房间名', trigger: 'blur' },
  { required: true, message: '请选择/输入房间名', trigger: 'change' }
]

// 选择imei
const check_imei = [
  { required: true, message: '请选择IMEI', trigger: 'change' }
]

// 校验规则名称
const check_rulename = [
  { required: true, message: '请输入规则名称', trigger: 'blur' },
  { max: 16, message: '规则名称为 1 ~ 16 位', trigger: 'blur' }
]

export {
  check_name_operator,
  check_name_chief,
  check_tel,
  check_user_name,
  check_pwd,
  _check_pwd,
  check_operator,
  check_project,
  check_role,
  check_project_name,
  check_build_name,
  check_build,
  check_some_device_name,
  check_num,
  check_floor,
  check_name_room,
  check_device_name,
  check_imei,
  check_rulename
}
