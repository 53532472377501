<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :title="isEdit ? '修改楼栋' : '添加楼栋'"
    width="600px"
  >
    <div class="form_box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="formRef"
        label-width="100px"
      >
        <el-form-item label="楼栋名称" prop="name_building">
          <el-input
            v-model.trim="ruleForm.name_building"
            maxlength="20"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { check_build_name } from '@/utils/validator.js'
import { AddBuildApi, EditBuildApi } from '@/api'

export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 编辑还是修改
      isEdit: false,
      // 项目id
      id_project: '',
      // 楼栋ID
      id_building: '',
      // 表单
      ruleForm: {
        name_building: ''
      },
      // 规则
      rules: {
        name_building: check_build_name
      }
    }
  },
  methods: {
    // 添加账号
    add(id_project) {
      this.isEdit = false
      this.id_project = id_project
      this.dialogVisible = true
    },

    // 编辑账号
    edit(row) {
      this.id_building = row.id
      this.isEdit = true
      this.dialogVisible = true
      this.$nextTick(() => {
        this.ruleForm.name_building = row.name_building
      })
    },

    // 点击确定添加楼栋
    confirm() {
      this.$refs.formRef.validate(_ => {
        if (!_) return
        if (this.isEdit) {
          this.confirmEdit()
        } else {
          this.confirmAdd()
        }
      })
    },

    // 添加
    async confirmAdd() {
      const params = {
        id_project: this.id_project,
        name_building: this.ruleForm.name_building
      }
      const { ret, data, msg } = await AddBuildApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('添加成功')
      this.dialogVisible = false
      this.$emit('refresh')
    },

    // 编辑
    async confirmEdit() {
      const params = {
        id_building: this.id_building,
        name_building: this.ruleForm.name_building
      }
      const { ret, data, msg } = await EditBuildApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('修改成功')
      this.dialogVisible = false
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
.form_box {
  padding: 64px 60px 32px;
}
</style>
