<template>
  <div class="build_cpt">
    <div class="operate_btn">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="addBuild"
        >新建楼栋</el-button
      >
    </div>

    <div class="table_box">
      <el-table
        :data="buildList"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#fafafb'
        }"
        size="small"
      >
        <el-table-column
          label="楼栋名称"
          prop="name_building"
        ></el-table-column>
        <el-table-column
          label="运营商"
          prop="name_operator"
          v-if="level === 100"
        ></el-table-column>
        <el-table-column label="项目" prop="name_project"></el-table-column>
        <el-table-column label="房间数量">
          <template v-slot="scope">
            <span>
              {{ scope.row.cnt_room.cnt }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="创建日期" prop="time_create"></el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <div class="table_control">
              <img
                src="../../../assets/table_edit.png"
                title="编辑"
                @click="editBuild(scope.row)"
              />
              <img
                src="../../../assets/table_delete.png"
                title="删除"
                @click="showAlert(scope.row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <ChangeBuild ref="changeBuildRef" @refresh="refresh" />

    <!-- 确认消息 -->
    <AlertCpt ref="alertRef" @confirm="delBuild" />
  </div>
</template>

<script>
import ChangeBuild from './dialogs/change_build.vue'
import { DelBuildApi } from '@/api'
import AlertCpt from '@/components/alert/alert.vue'

export default {
  components: { ChangeBuild, AlertCpt },
  props: ['buildList', 'id_project'],
  data() {
    return {
      // 权限级别
      level: '',
      // 要删除的项目信息
      delInfo: null
    }
  },
  methods: {
    // 添加楼栋
    addBuild() {
      this.$refs.changeBuildRef.add(this.id_project)
    },

    // 编辑楼栋
    editBuild(row) {
      this.$refs.changeBuildRef.edit(row)
    },

    // 显示弹窗
    showAlert(info) {
      this.delInfo = info
      this.$refs.alertRef.alert({
        msg: '您确定要删除该楼栋? 此操作不可恢复'
      })
    },

    // 删除楼栋
    async delBuild() {
      const params = {
        id_building: this.delInfo.id
      }
      const { ret, data, msg } = await DelBuildApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('删除成功')
      this.$emit('refresh')
    },

    // 子组件刷新
    refresh() {
      this.$emit('refresh')
    }
  },
  created() {
    this.level = this.$store.getters.level
  }
}
</script>

<style lang="scss" scoped>
.build_cpt {
  padding: 22px;
}

.operate_btn {
  text-align: right;
  padding-right: 35px;
}

.table_box {
  margin-top: 21px;
}
</style>
